import * as style from './style.module.scss'
import * as React from 'react'
import { Dialog, DialogBackdrop } from 'reakit/Dialog'

export default function Modal(props) {
  const { children, ...dialog } = props

  return (
    <DialogBackdrop className={style.dialogBackdrop} {...dialog}>
      <Dialog className={style.dialog} {...dialog}>
        <div className={style.dialogContent}>{props.children}</div>
      </Dialog>
    </DialogBackdrop>
  )
}
