// extracted by mini-css-extract-plugin
export var messageBody = "style-module--messageBody--34f05";
export var messageContent = "style-module--messageContent--c81a5";
export var messageList = "style-module--messageList--a48e1";
export var messageListingName = "style-module--messageListingName--54d8e";
export var messageListingTitle = "style-module--messageListingTitle--fd546";
export var messagePreview = "style-module--messagePreview--0a1ba";
export var messageStatus = "style-module--messageStatus--dc50b";
export var messageTab = "style-module--messageTab--40f25";
export var pageLink = "style-module--pageLink--f086f";
export var pageList = "style-module--pageList--fe501";
export var read = "style-module--read--6ac7e";
export var sidebar = "style-module--sidebar--cdebc";
export var unread = "style-module--unread--301c6";