import Header from '../Header'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import * as React from 'react'

function TemplateFullWidth(props) {
  return (
    <div>
      {/* Header */}
      <Header />

      {/* Site / Body */}
      <div className={style.body}>{props.children}</div>

      {/* Footer */}
      <div className={style.footerContainer}>
        <div className={style.footerContent}>
          <div className={style.text}>© doggos</div>
          <div>
            <Link to='/terms/'>Terms</Link>
            <Link to='/privacy/'>Privacy</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateFullWidth
