import Modal from '../Modal'
import * as style from './style.module.scss'
import { useLocation } from '@reach/router'
import Link from 'gatsby-link'
import * as React from 'react'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'

function Menu() {
  const logIn = useDialogState({ baseId: 'logIn' })
  const signUp = useDialogState({ baseId: 'signUp' })

  return (
    <>
      <DialogDisclosure className={style.login} {...logIn}>
        Login
      </DialogDisclosure>
      <DialogDisclosure className={style.cta} {...signUp}>
        Sign up
      </DialogDisclosure>
      {/* Email list signup */}
      <Modal {...signUp}>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Join the list</h2>
              <button
                className={style.buttonClose}
                onClick={() => signUp.hide()}
              >
                Close
              </button>
            </div>
            <p>
              Sign ups are not open yet. 💔
              <br />
              <br />
              Looking for local dog sitters in your area? Add your email address
              and we'll let you know as soon as we launch!
            </p>
            <form
              action='https://www.getdrip.com/forms/743233303/submissions'
              method='post'
              data-drip-embedded-form='743233303'
              id='drip-ef-743233303'
            >
              <div>
                <label htmlFor={'drip-email'}>Email address</label>
                <input
                  required
                  placeholder='your@email.com'
                  type='email'
                  id='drip-email'
                  name='fields[email]'
                />
              </div>
              <div>
                <button
                  type='submit'
                  value='Sign Up'
                  data-drip-attribute='sign-up-button'
                >
                  Sign up for the waitlist
                </button>
              </div>
            </form>
            <p>
              Already have an account?{' '}
              <DialogDisclosure className={style.link} {...logIn}>
                Login
              </DialogDisclosure>
            </p>
          </div>
          <p className={style.note}>
            By signing up, you agree to receive emails from us. We’ll only send
            you emails related to your account, and we <strong>never</strong>{' '}
            sell personal data.
          </p>
        </div>
      </Modal>

      {/* Login */}
      <Modal {...logIn}>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Login</h2>
              <button
                className={style.buttonClose}
                onClick={() => logIn.hide()}
              >
                Close
              </button>
            </div>
            <p>
              If you already have an account, enter your email address to
              receive a login link sent to your inbox.
            </p>
            <form>
              <label for={'email'}>Email address</label>
              <input
                name={'email'}
                type='email'
                placeholder='Your email address'
              />
              <button type='submit'>Login</button>
            </form>
            <p>
              Don't have an account?{' '}
              <DialogDisclosure className={style.link} {...signUp}>
                Sign up
              </DialogDisclosure>
            </p>
          </div>
          <p className={style.note}>
            By logging in, you agree to our Terms of Service, including an{' '}
            <Link to={'/terms#mutual_arbitration'}> arbitration clause</Link>.
            Please read these terms carefully.
          </p>
        </div>
      </Modal>
    </>
  )
}

function Header() {
  const location = useLocation()

  return (
    <>
      <div className={style.headerContainer}>
        <div className={style.headerContent}>
          <Link to='/' className={style.logo}>
            <span aria-label='ball' className={style.dot} />
            doggos
          </Link>
          <div>
            {/* <Link to='/search' className={style.search}>
              <span className={style.desktop}>Find a dog sitter</span>
              <span className={style.mobile}>Browse</span>
            </Link> */}
            {typeof window !== 'undefined' &&
            !location.pathname.startsWith('/message') ? (
              <Menu />
            ) : (
              <>
                <DialogDisclosure className={style.login}>
                  Login
                </DialogDisclosure>
                <DialogDisclosure className={style.cta}>
                  Sign up
                </DialogDisclosure>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
